import React from 'react';
import {Link} from "react-router-dom";
import Header from "../header/header";
import {authenticationService} from "../scripts/authentication.service";
import {URL} from "../scripts/constants";
import {cf} from "../scripts/custom-fetch";
import {v4 as UUID} from "uuid";
import {Card, CardActions, CardContent, CardMedia, FormControl, InputLabel, LinearProgress, MenuItem, TextField, InputAdornment, Select, Button, Tooltip, Pagination} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {withSnackbar} from "notistack";
import Content from "../api/content";
import {searchService} from "../scripts/search.service";

class Personas extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.searchContent = this.searchContent.bind(this);

        this.state = {
            error: null,
            isLoaded: false,
            open: false,
            openView: false,
            openReelView: false,
            openVideoView: false,
            openDelete: false,
            restore: false,
            deleteID: 0,
            linkID: '',
            currentPage: searchService.currentSearchValue.page || 1,
            itemsPerPage: 15,
            currentSearch: searchService.currentSearchValue.search || [],
            currentType: searchService.currentSearchValue.type || [],
            searchItems: [],
            deletedItems: [],
            newPersona: false,
            newPersonaUUID: "",
            persona: {
                name: '',
                title: '',
                type: '',
                pic: ''
            },
            items: [],
            currentUser: authenticationService.currentUserValue,
        };
    }

    callPersona(){
        const options = { method: 'GET', headers: {'Authorization': `Bearer ${authenticationService.currentUserValue.token}`} };
        cf.get( URL + "/db/getPersonas", options)
            .then((result) => {
                    if (this.state.copyID) {
                        let copy = result.find(obj => obj.id === this.state.copyID)
                        window.open(window.location.href+'/edit/'+copy.uuid)
                    }
                    this.setState({
                        items: result,
                        searchItems:  result.filter(obj => {return obj.internally_managed === 1}),
                        isLoaded: true
                    }, () => {
                        this.searchContent()
                    });
                },
                (error) =>{
                    this.setState({
                        error,
                        isLoaded: true
                    });
                }
            )
    }

    close(e){
        e.preventDefault();
        this.setState({
            open: false,
        })
    }

    open(){
        this.setState({
            open: true,
        })
    }

    openRestore = () => {
        Content.getDeletedItems(10).then(result => {
            if (result) {
                this.setState({
                    deletedItems: result,
                    restore: true
                })
            }
        })
    }

    closeRestore = () => {
        this.setState({
            restore: false
        })
    }

    openDelete = (item) => {
        this.setState({
            openDelete: true,
            deleteID: item
        })
    }

    closeDelete = () => {
        this.setState({
            openDelete: false
        })
    }

    openView(item) {
        this.setState({
            openView: true,
            linkID: item,
        })
    }

    closeView(e){
        e.preventDefault();
        this.setState({
            openView: false,
        })
    }

    openReelView(item) {
        this.setState({
            openReelView: true,
            linkID: item,
        })
    }

    closeReelView(e){
        e.preventDefault();
        this.setState({
            openReelView: false,
        })
    }

    openVideoView(item) {
        this.setState({
            openVideoView: true,
            linkID: item,
        })
    }

    closeVideoView(e){
        e.preventDefault();
        this.setState({
            openVideoView: false,
        })
    }

    setPage(event, value) {
        let data = searchService.currentSearchValue
        data.page = value
        searchService.setSearch(data)
        this.setState({
            currentPage: value
        })
    }

    handleChange(event) {
        let temp = this.state.persona;
        temp[event.target.name] = event.target.value;
        this.setState({persona: temp})
    }

    newPersona() {
        this.props.enqueueSnackbar("Adding new persona", {variant:"info"});
        let uuid = UUID()
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({
                'name':this.state.persona.name,
                'title':this.state.persona.title,
                'type':this.state.persona.type,
                'pic':this.state.persona.pic,
                'uuid': uuid,
            })
        };

        cf.get(`${process.env.REACT_APP_API_POST}/db/newPersona`, options)
            .then((result) => {
                    console.log(result)
                    this.props.enqueueSnackbar("Added new persona", {variant:"success"});
                    this.setState({
                        persona: {
                            name: '',
                            title: '',
                            type: '',
                            pic: ''
                        },
                        newPersona: true,
                        newPersonaUUID: uuid,
                    }, () => {this.callPersona()})
                },
                (error) =>{
                    this.props.enqueueSnackbar("Something went wrong", {variant:"error"});
                    console.log(error)
                }
            )
    }

    updateSearchText = (event) => {
        let data = searchService.currentSearchValue
        data.search = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentSearch: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    updateSearchType = (event) => {
        let data = searchService.currentSearchValue
        data.type = event.target.value
        searchService.setSearch(data)
        this.setState({
            currentType: event.target.value
        }, () => {
            this.searchContent()
        })
    }

    searchContent() {
        let results = this.state.items

        if (this.state.currentSearch.length !== 0) {
            if (isNaN(Number(this.state.currentSearch))){
                results = results.filter(obj => {return obj.title.toUpperCase().includes(this.state.currentSearch.toUpperCase())})
            } else {
                results = results.filter(obj => {return (obj.id+'').indexOf(this.state.currentSearch) > -1})
            }

        }

        let internal = false
        if (this.state.currentType.length !== 0) {
            let temp = this.state.currentType
            temp.forEach(item => {
                if (item === 1) {
                    results = results.filter(obj => {return obj.master_persona === 1})
                }
                if (item === 2) {
                    internal = true
                }
                if (item === 3) {
                    results = results.filter(obj => {return obj.type === 1})
                }
                if (item === 4) {
                    results = results.filter(obj => {return obj.type === 2})
                }
                if (item === 6) {
                    results = results.filter(obj => {return obj.type === 4})
                }
                if (item === 7) {
                    results = results.filter(obj => {return obj.type === 5})
                }
                if (item === 8) {
                    results = results.filter(obj => {return obj.type === 6})
                }
                if (item === 9) {
                    results = results.filter(obj => {return obj.type === 7})
                }

            })
        }
        if (!internal) {
            results = results.filter(obj => {return obj.internally_managed === 1})
        }

        this.setState({searchItems:results})
    }

    copy(id, type) {
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${authenticationService.currentUserValue.token}`},
            body: JSON.stringify({
                'id':id,
                'type':type,
            })
        };

        cf.get(`${process.env.REACT_APP_API_POST}/db/copyPersona`, options)
            .then((result) => {
                    console.log(result)
                    this.setState({copyID: result.insertId})
                    this.callPersona();
                },
                (error) =>{
                    console.log(error)
                }
            )
    }

    delete = () => {
        let state
        let msg1
        let msg2
        if (this.state.restore) {
            msg1 = "Restoring"
            msg2 = "Restored"
            state = 1
        } else {
            msg1 = "Deleting"
            msg2 = "Deleted"
            state = 3
        }
        this.props.enqueueSnackbar(msg1, {variant:"info"});
        this.setState({
            openDelete: false,
        })
        Content.disable(10,state,this.state.deleteID).then(result => {
            this.callPersona()
            this.setState({
                restore: false
            })
            console.log(result)
            this.props.enqueueSnackbar(msg2, {variant:"success"});
        })
    }

    componentDidMount() {
        this.callPersona();
    }

    render() {
        const { error, isLoaded, open, searchItems, openView, openReelView, openVideoView, linkID, currentType, persona, currentPage, currentSearch, itemsPerPage, openDelete, restore, deletedItems, currentUser, newPersona, newPersonaUUID } = this.state;
        const indexOfLastPost = currentPage * itemsPerPage;
        const indexOfFirstPost = indexOfLastPost - itemsPerPage;
        const perms = currentUser.perms
        let currentItems
        if (restore) {
            currentItems = deletedItems.slice(indexOfFirstPost, indexOfLastPost)
        } else {
            currentItems = searchItems.slice(indexOfFirstPost, indexOfLastPost)
        }
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <>
                    <Header/>
                    <div style={{width:"50%"}}>
                        <LinearProgress />
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <Header/>
                    <div style={{position:"absolute",top:"0",left:"0",marginLeft:"50px",padding:"15px",backgroundColor:"rgba(0, 0, 0, 0.65)", display:"flex", borderRadius: "0 0 5px"}}>
                        <TextField
                            label="Search"
                            variant="outlined"
                            focused
                            value={currentSearch}
                            className="foreInputWhite"
                            style={{marginRight:"10px", width:"250px", color:"white"}}
                            onChange={(e) => this.updateSearchText(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon color="primary" />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <FormControl style={{width:"100%"}} variant="outlined" color="primary" focused>
                            <InputLabel>Filter</InputLabel>
                            <Select
                                value={currentType}
                                onChange={this.updateSearchType}
                                multiple
                                className="foreInputWhite"
                                label="Type"
                            >
                                <MenuItem value={1}>Master</MenuItem>
                                <MenuItem value={2}>System Generated</MenuItem>
                                <MenuItem value={3}>Default</MenuItem>
                                <MenuItem value={4}>Reel</MenuItem>
                                <MenuItem value={6}>Gmail</MenuItem>
                                <MenuItem value={7}>Music</MenuItem>
                                <MenuItem value={8}>Article</MenuItem>
                                <MenuItem value={9}>Video</MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div style={{marginTop: "100px"}} className="social">
                        <div className="box">
                            <div className="items">
                                {currentItems.map((item) => (
                                    <Card key={item.id} style={{width:"340px", marginBottom:"20px", backgroundColor: "#424242", display: "flex", marginLeft: "27px"}}>
                                        <div style={{position: "relative", textAlign: "left", width: "190px", height: "170px"}}>
                                            {item.master_persona ? (<div className="master">Master</div>) : ""}
                                            <CardContent style={{backgroundColor: "#515151", color: "#fff", height: "calc(100% - 46px)"}}>
                                                <div style={{fontSize: "18px"}}>ID: {item.id}</div>
                                                <Tooltip title={
                                                    <React.Fragment>
                                                        <div style={{fontSize: "16px"}}>{item.title}</div>
                                                    </React.Fragment>
                                                } aria-label="add">
                                                    <div style={{fontSize: "18px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>Title: {item.title}</div>
                                                </Tooltip>
                                                <Tooltip title={
                                                    <React.Fragment>
                                                        <div style={{fontSize: "16px"}}>{item.name}</div>
                                                    </React.Fragment>
                                                } aria-label="add">
                                                    <div style={{fontSize: "18px", overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis"}}>Name: {item.name}</div>
                                                </Tooltip>
                                            </CardContent>
                                            <CardActions disableSpacing style={{display: "flex", justifyContent: "space-between"}}>
                                                <Link to={!perms.p2 ? `/personas` : `/personas/edit/${item.uuid}`}>
                                                    <Button disabled={!perms.p2} size="small" variant="contained" color="secondary">
                                                        Edit
                                                    </Button>
                                                </Link>
                                                {item.type === 1 ? <Button onClick={() => this.openView(item.uuid)} size="small" variant="contained" color="secondary">View</Button> : ""}
                                                {item.type === 2 ? <Button onClick={() => this.openReelView(item.uuid)} size="small" variant="contained" color="secondary">View</Button> : ""}
                                                {item.type === 4 ? <Link to={`/gmail/view/${item.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">View</Button></Link> : ""}
                                                {item.type === 5 ? <Link to={`/music/view/${item.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">View</Button></Link> : ""}
                                                {item.type === 6 ? <Link to={`/article/view/${item.uuid}`} target="_blank"><Button size="small" variant="contained" color="secondary">View</Button></Link> : ""}
                                                {item.type === 7 ? <Button onClick={() => this.openVideoView(item.uuid)} size="small" variant="contained" color="secondary">View</Button> : ""}
                                                <Button disabled={!perms.p1} onClick={() => this.copy(item.id, item.type)} size="small" variant="contained" color="secondary">
                                                    Copy
                                                </Button>
                                            </CardActions>
                                        </div>
                                        <div style={{position: "relative", height: "100%", width: "150px"}}>
                                            <div className={perms.p3 ? "delete" : "hide"} onClick={() => this.openDelete(item.id)} >
                                                {restore ? <RestoreFromTrashIcon color="primary" fontSize="large" /> : <DeleteIcon color="primary" fontSize="large" />}
                                            </div>
                                            {item.pic ? (
                                                <CardMedia image={item.pic} title="pic" style={{width:"100%", height: "100%"}}/>
                                            ) : (
                                                <div style={{display: "flex", justifyContent: "center", height: "calc(100% - 46px)", alignItems: "center", color: "white", fontSize: "24px"}}>No Src</div>
                                            )}
                                            {item.type === 1 ? <div className="type">Default</div> : ""}
                                            {item.type === 2 ? <div className="type">Reel</div> : ""}
                                            {item.type === 4 ? <div className="type">Gmail</div> : ""}
                                            {item.type === 5 ? <div className="type">Music</div> : ""}
                                            {item.type === 6 ? <div className="type">Article</div> : ""}
                                            {item.type === 7 ? <div className="type">Video</div> : ""}
                                        </div>
                                    </Card>
                                ))}
                            </div>
                            <div className="pagination-container">
                                <Pagination color="secondary" count={Math.ceil(searchItems.length / itemsPerPage)} page={currentPage} onChange={(event, page) => this.setPage(event, page)} />
                            </div>
                        </div>
                        <div className="links">
                            {restore ? <Button variant="contained" color="primary" onClick={() => this.closeRestore()} className="spacer">Normal</Button> : <Button disabled={!perms.p3} variant="contained" color="primary" onClick={() => this.openRestore()} className="spacer">Restore</Button>}
                            <Button disabled={!perms.p1} variant="contained" color="primary" onClick={() => this.open()} className="spacer">New</Button>
                            <Link to="/"><Button variant="contained" color="primary">Back</Button></Link>
                        </div>
                    </div>
                    <div className={(openDelete ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <p>Are you sure ?</p>
                            <div style={{display: "flex", marginTop: "10px"}}>
                                <Button variant="contained" color="primary" style={{flex: 1, marginRight: "10px"}} onClick={this.delete}>Yes</Button>
                                <Button variant="contained" color="primary" style={{flex: 1}} onClick={this.closeDelete}>Nope</Button>
                            </div>
                        </div>
                    </div>
                    <div className={(open ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <TextField className="spacer" variant="outlined" label="Title" value={persona.title} name="title" onChange={this.handleChange} />
                            <TextField style={{paddingTop:"10px"}} variant="outlined" label="Name" value={persona.name} name="name" onChange={this.handleChange} />
                            <TextField style={{paddingTop:"10px"}} variant="outlined" label="Picture" value={persona.pic} name="pic" onChange={this.handleChange} />
                            <FormControl style={{paddingTop:"10px"}} variant="outlined">
                                <InputLabel>Type</InputLabel>
                                <Select
                                    onChange={this.handleChange}
                                    label="Type"
                                    value={persona.type}
                                    name="type"
                                >
                                    <MenuItem value={1}>Default</MenuItem>
                                    <MenuItem value={2}>Reel</MenuItem>
                                    <MenuItem value={4}>Gmail</MenuItem>
                                    <MenuItem value={5}>Music</MenuItem>
                                    <MenuItem value={6}>Article</MenuItem>
                                    <MenuItem value={7}>Video</MenuItem>
                                </Select>
                            </FormControl>
                            <Button style={{marginTop:"10px"}} variant="contained" color="primary" onClick={() => this.newPersona()}>Submit</Button>
                            <Link to={!perms.p2 ? `/personas` : `/personas/edit/${newPersonaUUID}`}><Button disabled={!perms.p2 || !newPersona} style={{marginTop:"10px"}} variant="contained" color="primary">Go to Persona</Button></Link>
                            <Button style={{marginTop:"10px"}} variant="contained" color="primary" onClick={e => this.close(e)}>Close</Button>
                        </div>
                    </div>
                    <div className={(openView ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <Link to={`/facebook/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">Facebook</Button></Link>
                            <Link to={`/instagram/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">Instagram</Button></Link>
                            <Link to={`/pinterest/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">Pinterest</Button></Link>
                            <Link to={`/vk/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">VK</Button></Link>
                            <Link to={`/twitter/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">Twitter</Button></Link>
                            <Link to={`/xiao/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">Xiao</Button></Link>
                            <Button style={{marginTop:"10px"}} variant="contained" color="primary" onClick={e => this.closeView(e)}>Close</Button>
                        </div>
                    </div>
                    <div className={(openReelView ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <Link to={`/tiktok/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">TikTok</Button></Link>
                            <Link to={`/snapchat/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">Snapchat</Button></Link>
                            <Link to={`/douyin/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">Douyin</Button></Link>
                            <Button style={{marginTop:"10px"}} variant="contained" color="primary" onClick={e => this.closeReelView(e)}>Close</Button>
                        </div>
                    </div>
                    <div className={(openVideoView ? 'user-box' : 'user-box hide')}>
                        <div className="user-container">
                            <Link to={`/video/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">Video</Button></Link>
                            <Link to={`/videoland/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">Videoland</Button></Link>
                            <Link to={`/itvx/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">ITVX</Button></Link>
                            <Link to={`/tencent/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">Tencent</Button></Link>
                            <Link to={`/iq/view/${linkID}`} target="_blank"><Button size="small" variant="contained" color="secondary">IQIYI</Button></Link>
                            <Button style={{marginTop:"10px"}} variant="contained" color="primary" onClick={e => this.closeVideoView(e)}>Close</Button>
                        </div>
                    </div>
                </>
            );
        }
    }
}

export default withSnackbar(Personas);
